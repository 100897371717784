@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700);

body {
  background: #EEEEF4;
  color: #999;
  font-family: Roboto;
}

h1{
  font-weight: 100;
  font-size: 27pt;
  color: #E43;
  display: flex;
  margin-top: 20%;
  justify-content: center;
  align-items: center;
}

p{font-weight:300;}

.warning-content {
	position: absolute;
  top: 25%;
  width: 100%;
  height: 300px;
  text-align: center;
  margin: 0;
}