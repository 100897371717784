@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700);
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}
:root {
    --primary: #fff;   
}

.btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

.btn--primary {
    background-color: #fff;
    background-color: var(--primary);
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--small {
    padding: 5px 10px;
    font-size: 15px;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 18px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--mobile {
    text-align: center;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 24px;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
}

.btn--wide {
    padding: 12px 100px;
    font-size: 20px;
    
}

.btn--large:hover, .btn--medium:hover, .btn--mobile:hover, .btn--small:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
}

.btn--wide:hover {
    color: #242424;
    background-color: #fff;
    transition: all 0.2s ease-out;
}

.blue {
    background-color: #276afb;
    color: #fff;
    border: none;
}

.red {
    background-color: #f00946;
    color: #fff;
    border: none;
}

.primary {
    background-color: #242424;
    color: #fff;
    border: none;
}

.primary:hover {
    background-color: #fff;
    color: #242424;
    border: none;
}

.green {
    background-color: #25ce4a;
    color: #fff;
    border: none;
}

.green:hover {
    background-color: #242424;
}

.btn-link {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-decoration: none;
    padding: 8px;
    height: 100%;
    width: 100%;
}
.navbar {
    background: #1c2237;
    height: 80px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 1.2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
}

.navbar-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: 80px;
}

.container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.navbar-icon {
    margin-right: 0.5rem;
}

.nav-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    list-style: none;
    text-align: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; /*flex-end*/
}

.nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
}

.nav-item:hover {
    border-bottom: 2px solid #f00946;
}

.nav-links {
    color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
}

.fa-bars {
    color: #fff;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: flex-start;
                justify-content: flex-start; /*end or flex-end */
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #1c2237;
        left: 0;
        opacity: 1;
        transition: all 0.6s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover{
        color: #f00946;
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: all 0.3s ease;
    }

    .nav-item:hover {
        border: none;
    }

    .nav-item {
        width: 100%;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        -webkit-transform: translate(25%, 50%);
                transform: translate(25%, 50%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top:  0;
        right: 0;
        -webkit-transform: translate(-100%, 60%);
                transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-btn {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        width: 100%;
        height: 120px;
    }
}

body {
  background: #EEEEF4;
  color: #999;
  font-family: Roboto;
}

h1{
  font-weight: 100;
  font-size: 27pt;
  color: #E43;
  display: -webkit-flex;
  display: flex;
  margin-top: 20%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

p{font-weight:300;}

.warning-content {
	position: absolute;
  top: 25%;
  width: 100%;
  height: 300px;
  text-align: center;
  margin: 0;
}
